.header{
  display: flex;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  padding-bottom: 11px; //for overlay
  background:#BA2F37 repeating-linear-gradient(-45deg, transparent, transparent 12px,
    rgba(255, 255, 255, 0.07) 12px, 
    rgba(255, 255, 255, 0.07) 24px);
  .left{
    flex:1;
  }
  .middle{
    flex:8;
    display: flex;
    justify-content: center;
  }
  .right{
    flex:1
  }

  .backBtn{
    background-color: white;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    margin: 18px 18px 18px 12px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title{
    width: 50%;
    display: flex;
    justify-content: center;
    img{
      max-height: 68.55px;
    }
  }
  .bottomOverlay{
    position: absolute;
    bottom:0;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(203,97,104,0.79),#921B22);
    height: 11px;
    width: 100%;
  }
}