.slider_img{
    width: 100%;
}
.slider_con{
    position: absolute; 
    width: 100%; 
    top: 0px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 65%;
}
.carousellStyle{
    width: 80% !important;
    margin-left: auto;
    margin-right: auto;
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {

    .slider_con{
        width: 450px;
        margin-top: 295px;
    }

}