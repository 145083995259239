.container{
  background: #961B1F;
  display: flex;
  flex:1;
  flex-direction: column;
  position: relative;
}

.panel{
  flex:1;
  
  justify-content: center;
  
  width: 100%;
  background: linear-gradient(179.94deg, #921B22, #B51F28);

  .content{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  

  .topOverlay{
    position: absolute;
    height: 162px;
    background-image: url('../images/sticker/lamp-top.png');
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  .toolbar{
    width: 90%;
    margin: 0 auto;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .doBtn{
    background: #FFB8A9;
    border: none;
    width: 43.95px;
    height: 43.95px;
    box-shadow: 0px 3.19608px 7.9902px rgba(0, 0, 0, 0.25), inset 0px 0.79902px 1.59804px rgba(255, 255, 255, 0.5), inset 0px 3.19608px 3.19608px rgba(255, 255, 255, 0.25), inset 0px -3.19608px 1.59804px rgba(216, 39, 60, 0.6);
    border-radius: 23.9706px;
    opacity: 0.3;
    margin:0 3px;
    &.active{
      opacity: 1;
    }
  }
  .downloadBtn{
    display: flex;
    color: #920101;
    font-family: 'A-OTF Maru Folk Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    background: #F6A559;
    box-shadow: 0px 3.19608px 7.9902px rgba(0, 0, 0, 0.25), inset 0px 0.79902px 1.59804px rgba(255, 255, 255, 0.5), inset 0px 3.19608px 3.19608px rgba(255, 255, 255, 0.25), inset 0px -3.19608px 1.59804px rgba(216, 39, 60, 0.6);
    border-radius: 23.9706px;
    width: 162px;
    height: 44px;
    border: none;
    justify-content: center;
    align-items: center;
    opacity: 0.3;

    &.active{
      opacity: 1;
      position: relative;
    }

    &:before{
      content:url('../images/sticker/download-red.svg');
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .canvasDiv{
    margin-top:14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex:1;
    z-index: 2;
    >div{
      border-radius: 30px;
      background:#E0E0E0 repeating-linear-gradient(-45deg, transparent, transparent 18px,
      rgba(255, 255, 255, 0.2) 18px, 
      rgba(255, 255, 255, 0.2) 36px);
    }

    canvas{
      border-radius: 30px;
    }
  }

  .uploadDiv{
    width: 90vw;
    height: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    >input[type=file]{
      display: none;
    }
  }

  .uploadBtn{
    display: flex;
    flex-direction: column;
    width: 153.87px;
    height: 153.87px;
    background: #E4E4E4;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    font-family: 'A-OTF Maru Folk Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    border: none;
    &:before{
      content:url('../images/sticker/upload-black.svg');
    }
  }
}

.control{
  height: 162px;
  background-image: url('../images/sticker/lamp-bottom.png');
  background-size: cover;
  background-repeat: no-repeat;
  .eleBoardDiv{
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    
    z-index: 2;
    transition: all 0.5s;
    pointer-events: none;
    &.expanded{
      background: rgba(0, 0, 0, 0.7);
      pointer-events: all;
    }
  }
  .eleBoard{
    position: absolute;
    width: 100%;
    max-height: 568px;
    height: 80%;
    background-image: url('../images/sticker/ele-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    bottom: max(-68%,-490px);
    transition: all 0.5s;
    text-align: center;
    &.expanded{
      bottom:0;
      
    }
  }
  .closeBtn{
    width: 32px;
    height: 32px;
    background: white;
    border-radius: 32px;
    border: none;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s;
    top: -60px;
    >img{
      vertical-align: middle;
    }
    &.expanded{
      pointer-events: all;
      opacity: 1;
    }
  }
  .expandBtn{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top:10px;
    width:75%;
    height:50px;
    background: #216042;
    margin:auto;
    border-radius: 30px;
    border: none;
    color:white;
    font-family: 'A-OTF Maru Folk Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    pointer-events: all;
    &:before{
      content:url('../images/sticker/material-white.svg');
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .eleShowArea{
    background: #28694A;
    box-shadow: inset 0px 7.37661px 7.37661px rgba(0, 0, 0, 0.25);
    border-radius: 35.0389px;
    position: relative;
    width: 90%;
    top: 30px;
    margin: auto;
    max-height: 480px;
    height: 80%;
    overflow-y: scroll;
    
  }
  .eleScrollView{
    display: flex;
    flex-wrap: wrap;
    padding: 9px;
    .eleItem{
      flex:45%;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 15.04px;
      margin: 1%;
      height: 131px;
      justify-content: center;
      align-items: center;
      display: flex;
      border: none;
      >img{
        max-width: 100%;
      }
    }
  }
  .deleteBtn{
    width: 104px;
    height: 42px;
    background: #82161C;
    backdrop-filter: blur(3.5px);
    border-radius: 50px;
    border: none;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    top: -60px;
    >img{
      vertical-align: middle;
    }
    &.selected{
      pointer-events: all;
      opacity: 1;
    }
  }
}