.header_con{
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-bottom: 20px; 
    display: flex; 
    width: 100%; 
    background: none; 
    z-index: 999;
    align-items: flex-start;
}
.back_btn_con{
    text-align: left; 
    margin-left: 10px;
    margin-top: 12px;
    width:15%;
}

.back_btn{
    border: none;
    padding: 0;
    background: none;
    width: 32px; 
    height: 32px;
    >img{
        width: 100%;
    }
}
.has_title_back_btn{
    background: none;
    border: none;
    width: 32px; 
    height: 32px;
}
.header_title_img{
    width: 100%;
}

.header_long_width{
    width: 70%;
}

.header_short_width{
    width: 15%;
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {

    .header_con{
        width: 450px;
    }

}
