body{
    margin: 0; 
    padding: 0 !important; 
    background-color: #000000;
}

a {
    text-decoration: none; 
    cursor: pointer;
}

.tutorial_center{
    width: 100%;
    height: 100vh;
    margin: 0;
    background: #B51F28;
}

.header_con{
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-bottom: 20px; 
    display: flex; 
    width: 100%; 
    background: none; 
    z-index: 999;
    align-items: flex-start;
}

.back_btn_con{
    text-align: left; 
    margin-left: 10px;
    margin-top: 12px;
    width: 15%;
}

.back_btn{
    width: 32px; 
    height: 32px;
}

.header_short_width{
    width: 15%;
  }
  .header_long_width{
    width: 70%;
  }


.tutor_text_con{
    width: 80%; 
    height: 175px; 
    background: #9A292C; 
    box-shadow: inset 0px 4px 24px rgba(0, 0, 0, 0.25); 
    border-radius: 40px; 
    top: 200px; 
    z-index: 999; 
    display: flex; 
    align-items: center;
}

.tutor_text{
    margin: 0px; 
    text-align: center; 
    color: #ffffff; 
    font-size: 12px; 
    padding: 0px 32px;
}

.tutor_slide_num{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    color: #ffffff; 
    font-size: 132px; 
    z-index: 99; 
    opacity: 0.1; 
    margin-top: 18px;
}






@media only screen and (min-device-width: 375px) and (min-device-height: 700px) {
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
    .tutorial_center{
        width: 100%;
    }
}
/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {
    .tutorial_center{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .header_con{
        width: 450px;
    }




}

/* Media Queries */
@media screen and (max-width: 600px) {

}
