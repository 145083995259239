.page{
    background:linear-gradient(180deg,#B51F28 0%, #921B22 50%, #B51F28 100%);
    min-height: 100vh;

    .container{
        display: flex;
        align-items: center;
        flex-direction: column;
        .headerImg{
            width: 40.533vw;
            margin: 7px 0;
        }
    }

    .quizDiv{
        margin-top: 3.467vw;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 8vw;
        z-index: 1;
        .questionHint{
            font-family: 'A-OTF Maru Folk Pro';
            font-style: normal;
            font-weight: 700;
            font-size: 22.9091px;
            line-height: 21px;
            color: #FFFFFF;
            margin-bottom: 0.933vw;
            z-index: 1;
            width: 73.6vw;
            background: #28694A;
            border-radius: 27px;
            height: 9.33vw;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 1.333vw;
            margin-left: 0.667vw;
        }
        .questionBoard{
            position:absolute;
            width: 96.8vw;
            img{
                width: 100%;
            }
        }
        .question{
            font-family: 'A-OTF Maru Folk Pro';
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 39px;
            width: 89.067vw;
            height: 24.8vw;
            background: #D13740;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 19px;
            text-align: center;
            margin-left: 0.667vw;
            color: #FFFFFF;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        
    }

    .divLine{
        position:absolute;
        width: 100%;
        height: calc(100vh - 50vw - 15px);
        top: calc(50vw + 15px);
        background: linear-gradient(179.94deg, #A01D25 4.07%, rgba(203, 97, 104, 0.79) 5.08%, #921B22 5.81%, #921B22 7.33%, #B51F28 13.6%, #B51F28 13.6%);
    }

    .answersDiv{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 91.2vw;
        border-radius: 19px;
        background: repeating-linear-gradient(
            -45deg,
            #286d4c,
            #286d4c 10px,
            #D9D9D9 10px,
            #D9D9D9 20px,
            #D9D9D9 10px,
            #D9D9D9 30px,
            #B61F28 10px,
            #B61F28 40px,
            #D9D9D9 10px,
            #D9D9D9 50px,
            #D9D9D9 10px,
            #D9D9D9 60px,
        );
        filter: drop-shadow(0px 11px 24px rgba(0, 0, 0, 0.25));
        
        .innerAnswerDiv{
            margin: 1.2vw;
            width: 88.8vw;
            background: #D13740;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 19px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .answer{
            margin: 1.73vw;
            
            border: 1px solid #FFFFFF;
            border-radius: 10.6vw;
            display: flex;
            width: 85%;
            min-height: 10.6vw;
            margin-bottom: 12px;
            padding: 16px;
            align-items: center;
            background: transparent;
            color:white;
            >div{
                font-family: 'GenSenRounded JP';
                font-style: normal;
                font-weight: 700;
                &:first-child{
                    font-size: 46px;
                    line-height: 23px;
                    padding-left: 1.33vw;
                }
                &:last-child{
                    margin-left: 20px;
                    flex: 1 1 auto;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: left;
                }
            }
            
            /*&:nth-child(odd){
                background: #FFFFFF;
                color:#333333
            }
            &:nth-child(even){
                background: #1A8956;
                color:white
            }*/
        }
    }
}