.challenge_btn_con{
    position: relative; 
    width: 203px; 
    height: 55px; 
    top: 0px; 
    left: 0; 
    right: 0; 
    margin-top: 15px; 
    margin-left: auto; 
    margin-right: auto; 
    display: flex; 
    background: linear-gradient(180deg, #59A64C 0%, #2E8620 100%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.5), inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 2px #0B4D1A;
    border-radius: 30px; 
    border: 0px solid; 
    padding: 10px 5px; 
    align-items: center;
}
.challenge_icon_con{
    width: 30%;
}
.lock_icon{
    width: 36px; 
    height: 36px;
}
.challenge_btn_text{
    font-size: 16px; 
    color: #ffffff; 
    width: 70%; 
    padding-right: 10px;
}

.rechallenge_btn_con{
    position: relative; 
    width: 203px; 
    height: 55px; 
    top: 0px; 
    left: 0; 
    right: 0; 
    margin-top: 15px; 
    margin-left: auto; 
    margin-right: auto; 
    display: flex; 
    background: linear-gradient(180deg, #E8483E 0%, #E87151 100%); 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.5), inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 2px #AF3515; 
    border-radius: 30px; 
    border: 0px solid; 
    padding: 10px 5px; 
    align-items: center;
}

.rechallenge_icon_con{
    width: 30%;
}

.rechallenge_icon{
    width: 36px; 
    height: 36px;
}

.lock_btn_con{
    position: relative; 
    width: 203px; 
    height: 55px; 
    top: 0px; 
    left: 0; 
    right: 0; 
    margin-top: 15px; 
    margin-left: auto; 
    margin-right: auto; 
    display: flex; 
    background: linear-gradient(180deg, #A8A8A8 0%, #5F5F5F 100%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.5), inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 2px #373737;
    border-radius: 30px; 
    border: 0px solid; 
    padding: 10px 5px; 
    align-items: center;
}

.lock_icon_con{
    width: 30%;
}

.tutor_btn{
    position: relative; 
    width: 203px; 
    height: 55px; 
    text-align: center; 
    background: linear-gradient(180deg, #59A64C 0%, #2E8620 100%); 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.5), inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 2px #0B4D1A;border-radius: 30px; 
    border: 0px solid; 
    padding: 10px 5px; 
    align-items: center; 
    color: #ffffff; 
    font-size: 16px;
}

.tutor_btn_con{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100%; 
    margin-top: 145px; 
    z-index: 991;
}