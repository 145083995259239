body{
    margin: 0; 
    padding: 0 !important; 
    background-color: #000000;
}

a {
    text-decoration: none; 
    cursor: pointer;
}

.slider_center{
    width: 100%;
    height: 100vh;
    margin: 0;
    background: #BB2E32 !important;
}

.slider_bg_1{
    width: 100%; 
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
}












/* Galaxy Fold */
@media only screen and (min-device-width: 250px) and (max-device-width: 280px) {

}

/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
}
@media only screen and (min-device-width: 375px) and (min-device-height: 700px) {
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
    .slider_center{
        width: 100%;
    }
}
/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {
    .slider_center{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .header_con{
        width: 450px;
    }

    .slider_bg_1{
        width: 450px;
    }

}
 /* Media Queries */
 @media screen and (max-width: 600px) {

}