@import "theme";
.page{
    background:#BA2F37 repeating-linear-gradient(-45deg, transparent, transparent 12px,
    rgba(255, 255, 255, 0.07) 12px, 
    rgba(255, 255, 255, 0.07) 24px);
    min-height: 100vh;

    .container{
        display: flex;
        align-items: center;
        flex-direction: column;
        .headerImg{
            width: 30.133vw;
            top:0.779vw;
            position: absolute;
        }
        .bgDiv{
            position: absolute;
            width: 100vw;
            .bgTree{
                width: 100%;
                position: absolute;
                display: flex;
                >img{
                    width: 100%;
                }
            }
            .bgTreeBottom{
                width: 100%;
                position: absolute;
                display: flex;
                top:190vw;
                >img{
                    width: 100%;
                }
            }
            .resultTitle{
                width:49.6vw;
                margin: auto;
                position: absolute;
                top:6.67vw;
                left: 0;
                right: 0;
                img{
                    width: 100%;
                }
            }
            .topLight{
                width: 100%;
                position: absolute;
                z-index: 1;
                >img{
                    width: 100%;
                }
            }
            
            .bgSanta{
                width: 55.4%;
                position: absolute;
                z-index: 2;
                left: 20.266vw;
                top: 17.6vw;
                >img{
                    width: 100%;
                }
            }
        }
        .resultDiv{
            position: relative;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            margin-top: 34vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 3;
            .typeName{
                height:14.8vw;
                display: flex;
                justify-content: center;
                position: relative;
                >img{
                    height: 100%;
                }
            }
            .typeDesc{
                height:16.8vw;
                display: flex;
                justify-content: center;
                position: absolute;
                top: 9vw;
                left: 0;
                right: 0;
                z-index: 1;
                >img{
                    height: 100%;
                }
            }
            .typeDetailsDiv{
                position: relative;
                width: 96.133vw;
                height: 90.266vw;//delete later
                background-image: url('../images/quiz/result_border.png');
                background-size: cover;
                background-repeat: no-repeat;
            }
            .typeStatDiv{
                padding-top: 10vw;
            }
            .typeStat{
                display: flex;
                align-items: center;
                justify-content: center;
                
                width:81.333vw;
                margin: auto;
                border-radius: 2vw;
                background: repeating-linear-gradient(
                    -45deg,
                    #B43C42,
                    #B43C42 10px,
                    #BC333B 10px,
                    #BC333B 20px
                );
                padding: 2vw 0;
                >img{
                    width: 67.467vw;
                }
            }
            .typeDetails{
                width:81.333vw;
                color:white;
                font-size: em(14);
                margin: auto;
            }
            .typeFooter{
                display: flex;
                width:100%;
                margin: auto;
                margin-top: 2vw;
                z-index: 2;
                position: relative;
                .typeTags{
                    flex:1;
                    color: white;
                    font-size: em(12);
                    padding-left: 7.4vw;
                    font-family: 'A-OTF Maru Folk Pro';
                    font-style: normal;
                    font-weight: 700;
                    .typeTag{
                        display: inline-block;
                        margin-right: 3vw;
                    }
                }
                .tagImg{
                    flex:1;
                    >img{
                        width: 52.667vw;
                    }
                }
            }
            .middleLight{
                width: 100vw;
                left: -1.9335vw;
                position: absolute;
                z-index: 1;
                top: -13vw;
                pointer-events: none;
                >img{
                    width: 100%;
                }
            }
            .titleBorder{
                border: 1px solid rgba(0, 0, 0, 0.2);
            }

            .productsDiv{
                width: 94.133vw;
                height: 40vw;
                display: flex;
                margin: 0 2.933vw;
                position: relative;
                top: 5vw;
                .giftForYouDiv{
                    flex: 1;
                    margin-right: 1.4665vw;
                    background: repeating-linear-gradient(
                        -45deg,
                        #286d4c,
                        #286d4c 10px,
                        #D9D9D9 10px,
                        #D9D9D9 20px,
                        #B61F28 10px,
                        #B61F28 30px,
                        #D9D9D9 10px,
                        #D9D9D9 40px,
                    );
                    border-radius: 14px;
                    padding:0.5333vw;
                    .innerDiv{
                        width: 100%;
                        height: 100%;
                        background-color: #286648;
                        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 14px;
                        display: flex;
                        color:white;
                        font-size: em(12);
                        justify-content: center;
                        >div{
                            flex:50%;
                        }
                        .product{
                            padding: 1vw;
                            margin-top: 1vw;
                        }
                        .productImg{
                            text-align: center;
                            width: 100%;
                            height: 15vw;
                            >img{
                                max-width: 100%;
                                height:100%;
                            }
                        }
                        .productName{
                            text-align: center;
                        }
                    }
                    .divName{
                        position: relative;
                        top: -7.5vw;
                        >img{
                            width: 100%;
                        }
                    }
                }
                .giftToSendDiv{
                    flex: 1;
                    margin-left: 1.4665vw;
                    background: repeating-linear-gradient(
                        -45deg,
                        #B61F28 ,
                        #B61F28 10px,
                        #D9D9D9 10px,
                        #D9D9D9 20px,
                        #286d4c 10px,
                        #286d4c 30px,
                        #D9D9D9 10px,
                        #D9D9D9 40px,
                    );
                    border-radius: 14px;
                    padding:0.5333vw;
                    .innerDiv{
                        width: 100%;
                        height: 100%;
                        background-color: #B51F28;
                        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 14px;
                        display: flex;
                        color:white;
                        font-size: em(12);
                        justify-content: center;
                        >div{
                            flex:50%;
                        }
                        .product{
                            padding: 1vw;
                            margin-top: 1vw;
                        }
                        .productImg{
                            text-align: center;
                            width: 100%;
                            height: 15vw;
                            >img{
                                max-width: 100%;
                                height:100%;
                            }
                        }
                        .productName{
                            text-align: center;
                        }
                    }
                    .divName{
                        position: relative;
                        top: -7.5vw;
                        >img{
                            width: 100%;
                        }
                    }
                }
            }

            .shareDiv{
                position: absolute;
                display: flex;
                width: 59.56vw;
                height: 21.867vw;
                background-image: url("../images/quiz/green_r_step.svg");
                background-size: cover;
                background-repeat: no-repeat;
                align-self: end;
                top: 176vw;
                align-items: center;
                justify-content: center;
                .btnDiv{
                    flex:1;
                    text-align: center;
                    height: 100%;
                    width: 100%;

                    .btn1{
                        padding-bottom: 2vw;
                        &:before{
                            content:"1";
                            left: 2vw;
                        }
                        &:after{
                            content:"";
                            position:absolute;
                            background-image: url("../images/quiz/result_step_arrow_white.svg");
                            background-repeat: no-repeat;
                            background-size: contain;
                            width:1.86vw;
                            height:5.33vw;
                            left: 19vw;
                            top: 13vw;
                            
                        }
                    }
                    .btn2{
                        padding-bottom: 3vw;
                        &:before{
                            content:"2";
                            left: 23.5vw;
                        }

                        &:after{
                            content:"";
                            position:absolute;
                            background-image: url("../images/quiz/result_step_arrow_white.svg");
                            background-repeat: no-repeat;
                            background-size: contain;
                            width:1.86vw;
                            height:5.33vw;
                            left: 39vw;
                            top: 13.5vw;
                            
                        }
                    }
                    .btn3{
                        padding-bottom: 4vw;
                        &:before{
                            content:"3";
                            left: 43.5vw;
                        }
                    }

                    >button{
                        height: 100%;
                        border: none;
                        background-color: transparent;
                        color:white;
                        font-family: 'GenSenRounded JP';
                        font-size: em(12);
                        font-weight: 500;
                        display: flex;
                        margin: auto;
                        align-items: end;
                        &:before{
                            position: absolute;
                            font-family: 'A-OTF Maru Folk Pro';
                            position: absolute;
                            
                            font-style: normal;
                            font-weight: 700;
                            font-size: em(46);
                            line-height: 31px;
                            padding-bottom: 5vw;
                            /* identical to box height, or 84% */

                            display: flex;
                            align-items: center;

                            color: rgba(255, 255, 255, 0.1);
                        }
                    }
                }
            }

            .fdDiv{
                background-image: url('../images/quiz/bottom_board.png');
                width: 96.133vw;
                height: 77.467vw;
                background-repeat: no-repeat;
                background-size: cover;
                margin-top: 42vw;
                .fdInnerDiv{
                    display: flex;
                    padding: 12vw 3vw 5vw 3vw;
                    color:white;
                    font-style: normal;
                    font-weight: 700;
                    font-size:em(10);
                    .bestFd{
                        flex:1;
                        >div{
                            color:white;
                            &:first-child{
                                padding-left: 4vw;
                                margin-bottom: 2vw;
                            }
                        }
                    }
                    .richFd{
                        flex:1;
                        >div{
                            color:white;
                            &:first-child{
                                padding-left: 4vw;
                                margin-bottom: 2vw;
                            }
                        }
                    }
                    .fd{
                        &:not(:first-child){
                            color:#212121;
                            display: flex;
                            >div{
                                display: flex;
                                align-items: center;
                                flex: 1;
                                >span{
                                    padding: 3vw 3vw 3vw 5vw;
                                    min-width: 20vw;
                                    font-size: em(10);
                                    font-style: normal;
                                    font-weight: 500;
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                    position: relative;
                                    left: -6vw;
                                }
                            }
                            
                            &:nth-child(even){
                                &:before{
                                    content:"";
                                    background-image:url("../images/quiz/odd_fd.png");
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    width:18.667vw;
                                    height:16.533vw;
                                    z-index: 1;
                                }
                            }
                            &:nth-child(odd){
                                &:before{
                                    content:"";
                                    background-image:url("../images/quiz/even_fd.png");
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    width:18.667vw;
                                    height:16.533vw;
                                    z-index: 1;
                                }
                            }
                            
                        }
                    }
                }
            }
            .bottomLight{
                position: absolute;
                width: 100%;
                top: 178vw;
                pointer-events: none;
                display: flex;
                >img{
                    width: 100%;
                }
            }
        }
    }
}