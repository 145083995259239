body{
    margin: 0; 
    padding: 0 !important; 
    background-color: #000000;
}

a {
    text-decoration: none; 
    cursor: pointer;
}

.main_center{
    width: 100%;
    margin: 0;
}

.santa_bg_con{
    width: 100%; 
    position: absolute; 
    top: 550px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    background: #D13748; 
    padding-bottom: 1000px;
}

.video_bg_con{
    width: 100%; 
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
}

.photo_bg_con{
    width: 100%; 
    position: absolute; 
    top: 1390px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
}

.manning_logo_con{
    position: relative; 
    display: flex; 
    justify-content: center; 
    width: 100%;
}

.manning_logo_img{
    width: 30%; 
    padding: 5%;
}

.video_con{
    position: relative; 
    width: 100%; 
    margin-bottom: 645px;
}

.video_deco_con{
    position: relative; 
    display: flex; 
    justify-content: center; 
    width: 100%;
}

.recruit_btn_img{
    position: relative;
    width: 35%;
}

.video_img_con{
    position: absolute; 
    top: 67px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100%;
}

.video_img_con img{
    width: 95%;
}   

.video_title_con{
    position: absolute; 
    top: 0; 
    left: -19px; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100%;
}

.video_title_con img{
    width: 73%;
}

.deco_gift_left{
    width: 45%; 
    position: absolute; 
    top: 190px; 
    left: 0px;
}

.deco_gift_right{
    position: absolute; 
    top: 295px; 
    right: 0px; 
    width: 30%;
}

.video_char_con{
    position: absolute; 
    top: 300px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100%;
}

.video_char_con img{
    width: 50%;
}   

.deco_light_top{
    position: absolute; 
    top: 445px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100%;
}

.video_play_btn_con{
    position: absolute; 
    top: 235px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100%; 
    display: flex; 
    justify-content: center;
}

.video_play_btn{
    background: #1A8956; 
    width: 22%; 
    font-size: 16px; 
    color: #ffffff; 
    border-style: none !important; 
    border-radius: 50px; 
    padding: 10px 15px; 
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.video_play_btn_icon{
    width: 25%;
}

.video_play_btn_text{
    width: 75%;
}

.recruit_con{
    position: relative; 
    width: 100%; 
    margin-bottom: 100px;
}

.recruit_title_con{
    position: absolute; 
    width: 100%; 
    top: -65px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
}

.recruit_title_con img{
    width: 75%;
}

.recruit_text_bg{
    width: 90%; 
    background: #9A292C; 
    box-shadow: inset 0 0 10px #2A2A2A; 
    border-radius: 50px; 
    text-align: center; 
    color: #ffffff; 
    padding: 60px 0px;
}

.recruit_text_con{
    width: 90%; 
    text-align: center; 
    margin-left: auto; 
    margin-right: auto;
}

.recruit_text_title{
    font-size: 18px; 
    font-weight: 900; 
    margin: 0; 
    margin-bottom: 5px
}

.recruit_text_content{
    font-size: 14px; 
    margin: 0; 
    margin-top: 25px;
}

.recruit_btn_text{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0;
    margin-left: auto; 
    margin-right: auto; 
    font-size: 16px; 
    font-weight: 900; 
    margin-top: 5%;
}

.recruit_btn_text{
    margin-top: 6%;
}

.recruit_btn{
    position: absolute; 
    bottom: -35px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
}

.quiz_con{
    position: relative; 
    width: 100%; 
    margin-bottom: 186px;
}

.quiz_bg{
    width: 100%;
}

.deco_light_bottom{
    position: absolute; 
    top: -25px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100%;
}

.quiz_con_bg{
    position: absolute; 
    top: -20px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 80%;
}

.quiz_title_con{
    position: absolute; 
    top: 50px; 
    left: 0px; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100%; 
    text-align: left; 
    font-size: 37px; 
    color: #D13740; 
    text-shadow: 5px 5px 5px #000000; 
    -webkit-text-stroke: 0.25px #ffffff;
}

.quiz_title{
    margin: 0; 
    margin-left: 50px;
}

.quiz_btn_con{
    position: absolute; 
    bottom: -35px; 
    left: 0; 
    width: 100%; 
    display: flex; 
    justify-content: left;
}

.quiz_btn{
    background: #1A8956; 
    width: 30%; 
    font-size: 16px; 
    color: #ffffff; 
    border-style: none !important; 
    border-radius: 15px; 
    padding: 8px 10px; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    margin-left: 50px;
}
.header_short_width{
    width: 15%;
  }
  .header_long_width{
    width: 70%;
  }
  

.quiz_btn_text{
    width: 80%;
}

.quiz_btn_icon{
    width: 20%;
}

.quiz_btn_icon img{
    width: 60%;
}

.photo_con{
    position: relative; 
    width: 100%;
}

.bgLandingImgWidth{
    width: 100%;
}

.playBtnWidth{
    width: 80%
}

.photo_bg{
    width: 95%;
}

.photo_title{
    position: absolute; 
    top: -25px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 70%;
}

.photo_gallery_con{
    position: absolute; 
    top:55px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
}

.photo_gallery{
    display: flex; 
    width: 80%; 
    margin-left: auto; 
    margin-right: auto; 
    flex-flow: row wrap; 
    justify-content: space-between; 
    align-items: center; 
    margin-bottom: 25px;
}

.photo_thumbnail{
    width: 95px; 
    height: 95px; 
    border-radius: 30px; 
    margin-bottom: 5px;
    overflow: hidden; 
    background-size: cover; 
    background-position: center center; 
    background-repeat: no-repeat;
}

.photo_btn_con{
    position: relative; 
    width: 100%; 
    margin-top: 30px; 
    text-align: center;
}

.photo_btn{
    width: 80%; 
    padding: 15px 0px; 
    font-size: 16px; 
    color: #ffffff; 
    font-weight: 900; 
    background: linear-gradient(180deg, #59A64C 0%, #2E8620 100%); 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.5), inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 2px rgba(14, 100, 43, 0.6); 
    border-radius: 30px;
    border: 0px solid;
}

/* Galaxy Fold */
@media only screen and (min-device-width: 250px) and (max-device-width: 280px) {
    .video_con{
        margin-bottom: 505px;
    }

    .video_play_btn_con{
        top: 185px;
    }

    .video_play_btn{
        width: 35%;
    }

    .video_char_con{
        top: 230px;
    }

    .deco_gift_left{
        top: 150px;
    }

    .deco_gift_right{
        top: 235px;
    }

    .deco_light_top{
        top: 340px;
    }

    .santa_bg_con{
        top: 485px;
        padding-bottom: 1000px;
    }

    .recruit_con{
        margin-bottom: 75px;
    }

    .recruit_btn{
        width: 35%;
    }




    .quiz_con{
        margin-bottom: 88px;
    }

    .quiz_title_con{
        top: 33px;
        font-size: 30px;
    }

    .quiz_btn{
        font-size: 8px;
    }

    .photo_bg_con{
        top: 1100px;
    }

    .photo_gallery_con{
        top: 45px;
    }

    .photo_thumbnail{
        width: 65px;
        height: 65px;
    }

    .photo_btn_con{
        margin-top: 12px;
    }
}

/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
    .main_center{
        width: 100%;
    }
}
/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
    
    .photo_bg_con{
        top: 1465px;
    }

    .video_play_btn_con{
        top: 254px; 
    }

    .video_char_con{
        top: 330px;
    }

    .video_con{
        margin-bottom: 705px;
    }

    .photo_gallery_con{
        top: 70px;
    }

}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {
    .main_center{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
        background: #000000;
    }

    .santa_bg_con{
        width: 450px;
    }

    .video_bg_con{
        width: 450px;
    }

    .photo_bg_con{
        width: 450px;
    }
}


/* Media Queries */
@media screen and (max-width: 600px) {

}
